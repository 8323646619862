import { makeObservable, observable, action, computed } from "mobx"
import AppleStoreItem from "./AppleStoreItem"

class AppleStore {
  count = 0
  appleList = []
  completed = false
  constructor() {
    makeObservable(this, {
      completed: observable,
      appleList: observable,
      createApple: action,
      eatApple: action,
      nowNumber: computed,
      nowWeight: computed,
      oldNumber: computed,
      oldWeight: computed
    })
  }
  get nowNumber () {
    return this.appleList.filter(item => !item.isEat).length || 0
  }
  get nowWeight () {
    let s = 0
    this.appleList.filter(item => !item.isEat).map(item => 
      s += item.number
    )
    return s
  }
  get oldNumber () {
    return this.appleList.filter(item => item.isEat).length || 0
  }
  get oldWeight () {
    let s = 0
    this.appleList.filter(item => item.isEat).map(item => 
      s += item.number
    )
    return s
  }
  createApple () {
    this.completed = true
    this.count += 1
    this.appleList.push(new AppleStoreItem(this.count))
    this.completed = false
  }
  eatApple (index) {
    this.appleList[index].isEat = true
  }
}
export default AppleStore
