import { makeObservable, observable, action } from "mobx"

class AppleStoreItem {
  number= parseInt((Math.random()+2)*100)
  id= ''
  isEat= false
  constructor(id) {
    this.id = id
    makeObservable(this, {
      number: observable,
      id: observable,
      isEat: observable,
      toggle: action.bound
    })
  }
  toggle() {
    this.isEat = !this.isEat
  }
}
export default AppleStoreItem
