import { useRootStore } from "../../stores/RootStore"
import { observer } from "mobx-react-lite"

function Apple () {
    const { appleStore } = useRootStore()
    return <div className="apple">
        <div className="apple_header">苹果篮子</div>
        <div className="apple_count">
            <div className="apple_left">
                <p>当前</p>
                <p>{appleStore.nowNumber}个苹果,{appleStore.nowWeight}克</p>
            </div>
            <div className="apple_left"> 
                <p>已吃掉</p>
                <p>{appleStore.oldNumber}个苹果,{appleStore.oldWeight}克</p>
            </div>
        </div>
        <div className="apple_add">
            <div className="apple_list">
                {
                    appleStore.nowNumber ?
                    appleStore.appleList.filter(item => !item.isEat).map(item => {
                        return <div key={item.id} className="item">
                            <img className="item_img" src="./apple.png" />
                            <div className="item_title">
                                <p>红苹果-{item.id}号</p>
                                <p>{item.number}克</p>
                            </div>
                            <button className="item_button" onClick={() => appleStore.eatApple(item.id - 1)}>吃掉</button>
                        </div>
                    }) :
                    <div>苹果篮子空空如也</div>
                }
            </div>
            <div className="foo">
               <button className="footer_button" onClick={() => appleStore.createApple()}>{appleStore.completed ? '正在摘取中' : '摘苹果'}</button> 
            </div>
        </div>

    </div>
}

export default observer(Apple)